<cm-tag>
  <div class="tag-name { opts.isauto ? 'auto' : 'import' } { opts.ismaintenancetag ? 'maintenance-tag' : 'auto-tag' }" title="">{ opts.title }
    <div class="tooltiptext { opts.isleft ? 'left' : 'right' }">
      <p class="info-tag">登録日付: { opts.dayregister }</p>
      <p class="info-tag">タイプ: { opts.isauto ? '自動作成' : '手動' }</p>
    </div>
  </div>

  <style>
    :scope {
      padding: 2px 3px;
      word-break: break-all;
    }

    .tag-name {
      border-radius: 4px;
      cursor: pointer;
      font-size: 14px;
      padding: 4px 10px;
      border: 1px solid #b9e1ea;
      position: relative;
      display: inline-block;
      line-height: 100%;
    }

    .tag-name .tooltiptext {
      visibility: hidden;
      background-color: #fff;
      text-align: left;
      border-radius: 6px;
      position: absolute;
      z-index: 99999;
      bottom: 135%;
      margin-left: -12px;
      opacity: 0;
      transition: opacity 0.3s;
      border: solid 1px #b9e1ea;
      color: #666;
      padding: 0 10px;
      width: auto !important
    }

    .tag-name .left {
      margin-left: -200px;
    }

    .tag-name .tooltiptext::after {
      content: '';
      width: 8px;
      height: 8px;
      transform: rotate(45deg);
      background-color: white;
      left: 15px;
      bottom: -6px;
      position: absolute;
      border-right-style: solid;
      border-right-width: 1px;
      border-right-color: #b9e1ea;
      border-bottom-style: solid;
      border-bottom-width: 1px;
      border-bottom-color: #b9e1ea;
    }

    .tag-name .left::after {
      right: 15px;
      left: auto;
    }

    .tag-name:hover .tooltiptext {
      visibility: visible;
      opacity: 1;
    }

    .tag-name .tooltiptext .info-tag {
      margin: 5px 0;
      white-space: pre;
    }

    .auto {
      background: #b9e1ea;
      color: #666;
    }

    .auto-tag {
      background: #b9e1ea;
      color: #666;
    }

    .maintenance-tag {
      background: white;
      color: #666;
      outline: 3px solid #b9e1ea;
      outline-offset: -3px;
    }

    .import {
      background: #fff;
    }
  </style>
</cm-tag>
