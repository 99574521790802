
const images = {
  qa              :     require('../resources/mnt-qa.png'),
  widget          :     require('../resources/mnt-widget.png'),
  analysis        :     require('../resources/mnt-analysis.png'),
  account         :     require('../resources/mnt-account.png'),
  site_status     :     require('../resources/mnt-info.png'),
  home            :     require('../resources/mnt-home.png'),

  my_account      :     require('../resources/mnt-my-account-s.png'),

  flow            :     require('../resources/mnt-flow.png'),

  upload          :     require('../resources/mnt-upload.png'),
  upload_s        :     require('../resources/mnt-upload-s.png'),
  download_s      :     require('../resources/mnt-download-s.png'),

  delete          :     require('../resources/mnt-delete.png'),
  delete_over     :     require('../resources/mnt-delete-over.png'),

  ranking_l       :     require('../resources/mnt-ranking-l.png'),

  Account_browsing:     require('../resources/Account_browsing.png'),
  Account_edit    :     require('../resources/Account_edit.png'),
  Analysis_edit   :     require('../resources/Analysis_edit.png'),
  Analysis_browsing:    require('../resources/Analysis_browsing.png'),
  QA_browsing     :     require('../resources/QA_browsing.png'),
  QA_edit         :     require('../resources/QA_edit.png'),
  Widget_browsing :     require('../resources/Widget_browsing.png'),
  Widget_edit     :     require('../resources/Widget_edit.png'),

  sort_up         :     require('../resources/sort-up.png'),
  sort_down       :     require('../resources/sort-down.png'),
  scroll          :     require('../resources/scroll.png'),
  help            :     require('../resources/help.png'),
  help_hover      :     require('../resources/help-hover.png'),
  clear_new       :     require('../resources/clear_new.png'),
  comment         :     require('../resources/comment.png'),
}

export default images;
