import { getDateFromYMD, formatMD } from './datetime';

const TIMESTAMP_PER_DAY = 86400000;

/**
 * Generate data for chart
 * @param fromDate
 * @param toDate
 * @param data
 */
export function generateData(fromDate: string, toDate: string, data: Array<any>) {
  const startDate = new Date(fromDate);
  const originalDate = startDate;
  let monthSpan = 1;
  const endDate = new Date(toDate);
  let dataExpectedAnswer: Array<number> = [];
  let dataNotExpectedAnswer: Array<number> = [];
  let dataNotFoundAnswer: Array<number> = [];
  let dataOthers: Array<number> = [];
  let labels: Array<any> = [];
  let summaryData = {
    others: 0,
    expected_answer: 0,
    not_expected_answer: 0,
    not_found_answer: 0,
    inquiry: 0,
  }
  // If data in one month then show all data
  if (startDate.getFullYear() == endDate.getFullYear() && startDate.getMonth() == endDate.getMonth()) {
    data.forEach(function (item: any) {
      // Get list data for chart
      dataExpectedAnswer.push(item['expected_answer']);
      dataNotExpectedAnswer.push(item['not_expected_answer']);
      dataNotFoundAnswer.push(item['not_found_answer']);
      dataOthers.push(item['others']);
      labels.push(getDateFromYMD(item['title']));
      // Count data summary
      summaryData.others += item['others'];
      summaryData.expected_answer += item['expected_answer'];
      summaryData.not_expected_answer += item['not_expected_answer'];
      summaryData.not_found_answer += item['not_found_answer'];
      summaryData.inquiry += item['inquiry'];
    });
  }
  else {
      monthSpan = endDate.getMonth() - startDate.getMonth() + (12 * (endDate.getFullYear() - startDate.getFullYear())) + 1;
      //Get data of the first date
      dataExpectedAnswer.push(data[0]['expected_answer']);
      dataNotExpectedAnswer.push(data[0]['not_expected_answer']);
      dataNotFoundAnswer.push(data[0]['not_found_answer']);
      dataOthers.push(data[0]['others']);
      labels.push(getDateFromYMD(data[0]['title']));
      // Count summary data of the first date
      summaryData.others += data[0]['others'];
      summaryData.expected_answer += data[0]['expected_answer'];
      summaryData.not_expected_answer += data[0]['not_expected_answer'];
      summaryData.not_found_answer += data[0]['not_found_answer'];
      summaryData.inquiry += data[0]['inquiry'];
      //Remove first element out of array data
      data.shift();
      let not_expected_count = 0;
      let expected_count = 0;
      let not_found_count = 0;
      let other_count = 0;
      data.forEach(function (item: any, index: number) {
        // Count data summary
        summaryData.others += item['others'];
        summaryData.expected_answer += item['expected_answer'];
        summaryData.not_expected_answer += item['not_expected_answer'];
        summaryData.not_found_answer += item['not_found_answer'];
        summaryData.inquiry += item['inquiry'];
        if ((index + 1) % monthSpan === 0 ) {
            expected_count += item['expected_answer'];
            not_expected_count += item['not_expected_answer'];
            not_found_count += item['not_found_answer'];
            other_count += item['others'];
            dataExpectedAnswer.push(Math.round(expected_count/monthSpan * 10) / 10);
            dataNotExpectedAnswer.push(Math.round(not_expected_count/monthSpan * 10) /10);
            dataNotFoundAnswer.push(Math.round(not_found_count/monthSpan * 10) / 10);
            dataOthers.push(Math.round(other_count/monthSpan * 10) / 10);
            labels.push(getDateFromYMD(item['title']));
            expected_count = 0;
            not_expected_count = 0;
            not_found_count = 0;
            other_count = 0;
        }
        else {
          // Last element in data array
          if (index + 1 === data.length) {
            const durationFinal = data.length % monthSpan;
            expected_count += item['expected_answer'];
            not_expected_count += item['not_expected_answer'];
            not_found_count += item['not_found_answer'];
            other_count += item['others'];
            dataExpectedAnswer.push(Math.round(expected_count/durationFinal * 10) / 10);
            dataNotExpectedAnswer.push(Math.round(not_expected_count/durationFinal * 10) / 10);
            dataNotFoundAnswer.push(Math.round(not_found_count/durationFinal * 10) / 10);
            dataOthers.push(Math.round(other_count/durationFinal * 10) / 10);
            labels.push(getDateFromYMD(item['title']));
          }
          else {
            expected_count += item['expected_answer'];
            not_expected_count += item['not_expected_answer'];
            not_found_count += item['not_found_answer'];
            other_count += item['others'];
          }
        }
      });
  }
  return { expectedAnswer: dataExpectedAnswer, notExpectedAnswer: dataNotExpectedAnswer, notFoundAnswer: dataNotFoundAnswer, others: dataOthers, days: labels, summaryData: summaryData, originalDate, monthSpan };
}

/**
 * Generate x axes
 * @param val
 * @param index
 * @param monthSpan
 * @param originalDate
 */
export function generateXAxes(val: any, index: any, monthSpan: any, originalDate: any) {
  if (index && monthSpan > 1) {
    const diffDays = Math.round(Math.abs((new Date(val).valueOf() - new Date(originalDate).valueOf()) / TIMESTAMP_PER_DAY));
    if (diffDays % monthSpan === 0 ) {
      const fromDate = new Date(new Date(val).getTime() - (monthSpan - 1) * TIMESTAMP_PER_DAY);
      return formatMD(fromDate) + '〜' + formatMD(new Date(val));
    } else {
      const fromDate = new Date(new Date(val).getTime() - (diffDays % monthSpan - 1 ) * TIMESTAMP_PER_DAY);
      return formatMD(fromDate) + '〜' + formatMD(new Date(val));
    }
  } else {
    return formatMD(new Date(val));
  }
}
