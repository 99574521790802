
declare var Promise:any;

// https://qiita.com/DUxCA/items/801e88462eb5c84af97d
import { getSession, saveSession } from './session-storage';
import { currentHash, gotoLogin, gotoServiceUnavailable, gotoSelectDomain, setHash } from './hash';
const FAQ_LAST_TIME_KEY = 'FAQ_LAST_TIME';

export function urlWithQuery(url:string, system?: string, obj?:{ [key:string]:any }) {
  url = url[0] === '/' ? url : `/${url}`;
  system = system || 'master';
  const base_url = (url.indexOf('/me') === 0 || system !== 'admin') ? `/api/v1/${system}${url}` : `/api/v1/${system}/${currentHash().domain}${url}`;
  if (!obj) obj = {};
  obj._ = new Date().getTime(); // IEのキャッシュ対策
  const query = Object.keys(obj).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`).join('&');
  const sep = url.indexOf('?') == -1 ? '?' : '&';
  return `${base_url}${sep}${query}`;
}

const saveTimeLastUpdateFaq = (res: any) => {
  const domainId = currentHash().domain;
  const key = `${FAQ_LAST_TIME_KEY}_${domainId}`;
  const faqLastTimeResponse = res.headers.get(key);
  if (!faqLastTimeResponse) return;
  let dataSave = getSession(key) || {};
  dataSave = {...dataSave, ...JSON.parse(faqLastTimeResponse)};
  saveSession(key, dataSave);
}

export function saveTimeFinal(dataSave: any) {
  const domainId = currentHash().domain;
  const key = `${FAQ_LAST_TIME_KEY}_${domainId}`;
  saveSession(key, dataSave);
}

export function getDataLastUpdateFaq (latest: any) {
  const domainId = currentHash().domain;
  const key = `${FAQ_LAST_TIME_KEY}_${domainId}`;
  const dataSave = getSession(key) || '{}';
  if (!latest) return '[]';
  const resquest: any = [];
  if (latest.startsWith('ALL')) {
    const list = ['faq', 'application', 'hearing', 'word', 'config', 'label', 'categories'];
    if (latest === 'ALL_AND_APPLY') {
      list.push('apply');
    }
    list.forEach((item: any) => {
      resquest.push({ [item]: dataSave[item] || '' });
    });
  } else {
    resquest.push({ [latest]: dataSave[latest] || '' });
  }
  return JSON.stringify(resquest) ;
}

const getJson = (res:any) => {
  // SERVICE UNAVAILABLE
  if (res.status === 503) {
    gotoServiceUnavailable();
  }
  if (res.ok) {
    saveTimeLastUpdateFaq(res);
    return res.json()
  }
  if (res.status == 401) {
    res.json().then((json: any) => {
      // Go to page #undefined/home when deny access current ip
      if (json.err === 'IP not allow to access' && location.hash !== '#login') {
         gotoSelectDomain();
      } else {
        gotoLogin();
      }
    }).catch((ex: any) => {
      gotoLogin();
    });
  }
  if (res.status == 403) {
    setHash(currentHash().domain, 'home')
    location.reload();
    return;
  }
  if (res.status == 400) {
    return res.json();
  }
  throw new Error(res.statusText);
}

export function getDataNew(url:string, system?: string, obj?:{ [key:string]:any }) {
  return fetch(urlWithQuery(url, system, obj), { credentials: "same-origin" }).then(res => getJson(res));
}

export function postDataNew(url:string, system?: string, obj?:{ [key:string]:any }, latest?: any) {
  const body = obj && Object.keys(obj).reduce((o,key)=>(o.append(key, obj[key]), o), new FormData());
  let lastUpdate = getDataLastUpdateFaq(latest);
  let headers = {};
  if (lastUpdate) {
    headers = { latest: lastUpdate };
  }
  return fetch(urlWithQuery(url, system), { method:'POST', body, credentials: "same-origin", headers }).then(res => getJson(res));
}

export function putDataNew(url:string, system?: string, obj?:{ [key:string]:any }, latest?: any) {
  const body = obj && Object.keys(obj).reduce((o,key)=>(o.append(key, obj[key]), o), new FormData());
  let lastUpdate = getDataLastUpdateFaq(latest);
  let headers = {};
  if (lastUpdate) {
    headers = { latest: lastUpdate };
  }
  return fetch(urlWithQuery(url, system), { method:'PUT', body, credentials: "same-origin", headers }).then(res => getJson(res));
}

export function deleteDataNew(url:string, system?: string, query?:{ [key:string]:any }, latest?: any, obj?: { [key:string]:any }) {
  let lastUpdate = getDataLastUpdateFaq(latest);
  let headers = {};
  if (lastUpdate) {
    headers = { latest: lastUpdate };
  }
  const body = obj && Object.keys(obj).reduce((o,key)=>(o.append(key, obj[key]), o), new FormData());
  return fetch(urlWithQuery(url, system, query), { method:'DELETE', credentials: "same-origin" , headers , body }).then(res => getJson(res));
}
