import BaseManagement from './base/base-management';
import labelManagement from './label-management';

class ConfigManagement extends BaseManagement {
  public async getSelectedLabels() {
    return labelManagement.getSelectedLabels();
  }

  public getListLabels() {
    return labelManagement.getListLabels();
  }
}

export default new ConfigManagement();
