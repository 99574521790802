import BaseManagement from './base/base-management';
import labelManagement from './label-management';

class QaManagement extends BaseManagement {
  _isFirstMount: boolean = false;

  public async getSelectedLabels() {
    return labelManagement.getSelectedLabels();
  }

  public getListLabels() {
    return labelManagement.getListLabels();
  }

  public resetSelectedCategory() {
    if (this._instance) {
     this._instance.selectedCategories = {};
    }
  }

  public setIsFirstMount(isFirstMount: boolean) {
    this._isFirstMount = isFirstMount;
  }

  public getIsFirstMount() {
    return this._isFirstMount;
  }
}

export default new QaManagement();
