import popupUtil from './popup-util';
// 日時処理。複雑になって来たら moment 使う

const pad = (n:number) => ('0' + (n)).slice(-2);

export function formatYMDja(d:Date) {
  return `${d.getFullYear()}年${pad(d.getMonth()+1)}月${pad(d.getDate())}日`;
}

export function formatMDHM(d:Date) {
  return `${pad(d.getMonth()+1)}/${pad(d.getDate())} ${d.getHours()}:${pad(d.getMinutes())}`;
}

export function formatMD(d: Date) {
  return `${pad(d.getMonth() + 1)}/${pad(d.getDate())}`;
}

export function getDateFromYMD(date: any) {
  return new Date(date.slice(0, 4), parseInt(date.slice(4, 6)) -1, date.slice(6, 8));
}

export function addDay(d:Date, num:number) {
  d.setDate(d.getDate() + num);
  return d;
}
export function formatYMja(d:Date) {
  return `${d.getFullYear()}年${pad(d.getMonth() + 1)}月`;
}
export function formatYM(d:Date) {
  return `${d.getFullYear()}/${pad(d.getMonth() + 1)}`;
}

/**
 * Format yyyy/mm/dd
 * @param {*} d
 */
export function formatYMDWithSlash(d:Date) {
  return `${d.getFullYear()}/${pad(d.getMonth()+1)}/${pad(d.getDate())}`;
}

/**
 * Format yyyy/mm/dd hh:mm:ss
 * @param {*} time
 */
export function formatYMDHHMMSS(time: number) {
  const d = new Date(time);
  const pad = (n: number) => ('0' + (n)).slice(-2);
  return `${d.getFullYear()}/${pad(d.getMonth()+1)}/${pad(d.getDate())} ${pad(d.getHours())}:${pad(d.getMinutes())}:${pad(d.getSeconds())}`;
}

 /**
 * Check format of the month and year
 * @param date
 */
const checkYearMonthFormat = (date:string) => {
  if (!/^\d{4}\/\d{1,2}$/.test(date)) {
    return false; 
  }
  var parts = date.split("/");
  var month = parseInt(parts[1], 10);
  var year = parseInt(parts[0], 10);
  if (year < 1000 || year > 3000 || month == 0 || month > 12) return false;
  return true;
}

 /**
 * Check month contain in rank
 * @param date
 * @param rankingMonth
 */
const checkMonthInRank = (ym:string, rankingMonth:any) => rankingMonth.filter(function(e:any) { return e.key === ym; }).length > 0;

 /**
 * Validate date
 * @param startMonth
 * @param endMonth
 * @param rankingMonth
 */
export function checkMonthYear(startMonth: string, endMonth: string, rankingMonth: any) {
  if (!checkYearMonthFormat(startMonth)) {
    popupUtil.alert('対象期間FROMが不正です。\n対象期間FROMはドロップダウンから選択してください。 ');
    return false;
  }
  if (!checkYearMonthFormat(endMonth)) {
    popupUtil.alert('対象期間TOが不正です。\n対象期間TOはドロップダウンから選択してください。');
    return false;
  }
  if (!checkMonthInRank(startMonth, rankingMonth)|| !checkMonthInRank(endMonth, rankingMonth)) {
    popupUtil.alert('対象期間FROM又は対象期間TOが不正です。\n対象期間FROMと対象期間TOはドロップダウンから選択してください。');
    return false;
  }
  return true;
}

/**
 * Check start month is less than end month
* @param startMonth
 * @param endMonth
 */
export function isValidMonthRange(startMonth: any, endMonth: any) {
  return new Date(startMonth.concat('/01')) < new Date(endMonth.concat('/01'));
}
