import BaseManagement from './base/base-management';

class RightBarManagement extends BaseManagement {
  public getNumberFaq() {
    return this._instance && this._instance.numberFaq;
  }
  public setItNumberFaq(numberFaq: number) {
    this._instance.numberFaq = numberFaq;
  }
}

export default new RightBarManagement();
