
declare var Promise:any;

// https://qiita.com/DUxCA/items/801e88462eb5c84af97d

import { currentHash, gotoLogin, gotoServiceUnavailable, gotoSelectDomain } from './hash';

export function urlWithQuery(url:string, system?: string, obj?:{ [key:string]:any }) {
  url = url[0] === '/' ? url : `/${url}`;
  system = system || 'master';
  const base_url = (url.indexOf('/me') === 0 || system !== 'admin') ? `/api/v1/${system}${url}` : `/api/v1/${system}/${currentHash().domain}${url}`;
  if (!obj) obj = {};
  obj._ = new Date().getTime(); // IEのキャッシュ対策
  const query = Object.keys(obj).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`).join('&');
  const sep = url.indexOf('?') == -1 ? '?' : '&';
  return `${base_url}${sep}${query}`;
}

const getJson = (res:any) => {
  // SERVICE UNAVAILABLE
  if (res.status === 503) {
    gotoServiceUnavailable();
  }
  if (res.ok) {
    return res.json()
  }
  if (res.status == 401) {
    res.json().then((json: any) => {
      // Go to page #undefined/home when deny access current ip
      if (json.err === 'IP not allow to access' && location.hash !== '#login') {
         gotoSelectDomain();
      } else {
        gotoLogin();
      }
    }).catch((ex: any) => {
      gotoLogin();
    });
  }
  if (res.status == 403) {
    throw new Error('Forbidden');
  }
  throw new Error(res.statusText);
}

export function getData(url:string, system?: string, obj?:{ [key:string]:any }) {
  return fetch(urlWithQuery(url, system, obj), { credentials: "same-origin" }).then(res => getJson(res));
}

export function postData(url:string, system?: string, obj?:{ [key:string]:any }) {
  const body = obj && Object.keys(obj).reduce((o,key)=>(o.append(key, obj[key]), o), new FormData());
  return fetch(urlWithQuery(url, system), { method:'POST', body, credentials: "same-origin" }).then(res => getJson(res));
}

export function putData(url:string, system?: string, obj?:{ [key:string]:any }) {
  const body = obj && Object.keys(obj).reduce((o,key)=>(o.append(key, obj[key]), o), new FormData());
  return fetch(urlWithQuery(url, system), { method:'PUT', body, credentials: "same-origin" }).then(res => getJson(res));
}

export function deleteData(url:string, system?: string, obj?:{ [key:string]:any }) {
  return fetch(urlWithQuery(url, system, obj), { method:'DELETE', credentials: "same-origin" }).then(res => getJson(res));
}
