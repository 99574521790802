// IE 11 で対応していない関数等を Polyfill で対応
import "./polyfills";

import riot from 'riot'
import './containers'

import './style.css';

document.body.innerHTML = `<app></app>`;
const [app] = riot.mount('app');

window.addEventListener('hashchange', () => app.update());
