
const qa_help = {
  config: {
    not_found_message: "回答が見つからなかった場合のメッセージを指定します。\nアップロードする_config_ シートのC列にメールアドレスまたはトークルーム名を指定すると、見つからなかった内容が通知されます。\n＠でカテゴリーを指定することで、カテゴリーごとに設定できます。サブカテゴリーを指定する場合は全ての階層のカテゴリを＠で指定します。\n例) not_found_massage@カテゴリー＠サブカテゴリー",
    not_found_messages: "@でカテゴリーを指定して、そのカテゴリーで回答が見つからなかった場合のメッセージを指定します。\nメールアドレスが指定されていれば、見つからなかった内容がメールされます。",
    not_satisfied_message: "回答が期待と異なった場合のメッセージを指定します。\nアップロードする_config_ シートのC列にメールアドレスまたはトークルーム名を指定すると、見つからなかった内容が通知されます。\n＠でカテゴリーを指定することで、カテゴリーごとに設定できます。サブカテゴリーを指定する場合は全ての階層のカテゴリを＠で指定します。\n例) not_satisfied_massage@カテゴリー＠サブカテゴリー",
    not_satisfied_messages: "@でカテゴリーを指定して、そのカテゴリーで回答が期待と異なった場合のメッセージを指定します。\nメールアドレスが指定されていれば、見つからなかった内容がメールされます。",
    not_filtering_num: "絞り込みを終了する件数(1〜20)を指定します。この値以下に絞り込まれると、候補の質問文を表示します。",
    categories_num_per_page: "1メッセージあたりの表示カテゴリー数(1〜20)を指定します。",
    // tags_num_per_page: "1メッセージあたりの表示タグ数(1〜20)を指定します。",
    keywords_num_per_page: "自動生成するカテゴリーボタンの数(1〜20)を指定します。",
    user_tag: "言葉の揺らぎを学習する場合は on を指定します。 off を指定すると、タグは自動登録されません。",
    first_categories: "最初のメッセージにカテゴリーを表示する場合は on を指定します。非表示の場合は off を指定します。入力欄を非表示にする時は on_noinput を指定します。",
    next_categories: "次のカテゴリー群を表示するためのボタン名を指定します。",
    prev_categories: "前のカテゴリー群を表示するためのボタン名を指定します。",
    no_target: "サブカテゴリーと同じ階層に回答が存在する場合のボタン名を指定します。",
    prompt_candidates: "候補の質問文と一緒に表示するメッセージを指定します。",
    prompt_category: "カテゴリーで絞り込みするときのメッセージを指定します。",
    prompt_confirm: "回答文を表示した後に、期待通りの内容だったか確認するためのメッセージを指定します。この質問には「はい」「いいえ」で答えていただきます。",
    prompt_feedback_ok: "prompt_confirmでの確認で「はい」が選択されたときに表示するメッセージを指定します。",
    prompt_keyword: "カテゴリーボタンを表示しないで、テキスト入力のみで絞り込みするときのメッセージを指定します。",
    prompt_feedback_okes: "prompt_confirmでの確認で「はい」が選択されたときに表示するメッセージを指定します。",
    prompt_root: "最初のメッセージを指定します。",
    reduce_filter: "絞り込み条件をひとつ減らすためのボタン名を指定します。空白を指定するとボタンが非表示になります。\ntoolbar_buttons:onの場合は、ツールバーにボタンが表示されます。",
    go_home: "最初のメッセージまで戻るためのボタン名を指定します。指定のない場合はボタンが非表示になります。\ntoolbar_buttons:onの場合は、ツールバーにボタンが表示されます。",
    clustering_type: "カテゴリーを動的に生成する手法として keyword（共通するキーワード） もしくは kmeans（K平均法） を指定します。指定のない場合は keyword になります。自動生成しない場合は off を指定します。",
    common_words: "システム組み込みの類義語を利用する場合は on を指定します。利用しない場合は off を指定します。",
    toolbar_buttons: "最初のメッセージまで戻るためのボタンと絞り込み条件を減らすためのボタンを、入力欄の上に表示する場合は on を指定します。 \noff を指定すると、選択肢として表示されます。",
    full_search: "テキスト入力による絞り込みにおいて、常に検索対象に回答文とカテゴリーを含めたい場合に on にします。\noff にした場合は、質問文とタグが優先的に検索されます。half の場合は質問文とタグに加え、カテゴリも優先的に検索されます。",
    not_narrowed_down: "入力したキーワードが、残り全ての回答候補に含まれているため絞り込めない場合のメッセージを指定します。",
    toolbar_answers: "よくある質問を、入力欄の上に表示する場合は表示する数(0〜10)を指定します。0を指定すると非表示になります。",
    completion_notice: "申し込みを受付けたときの通知先を指定します。\nアップロードする_config_ シートのB列にメールアドレスまたはトークルーム名を指定します。\n＠でカテゴリーを指定することで、カテゴリーごとに設定できます。サブカテゴリーを指定する場合は全ての階層のカテゴリを＠で指定します。\n例） completion_notice@カテゴリー＠サブカテゴリー",
    completion_notices: "申し込みを受付けたときの通知先を指定します。\nアップロードする_config_ シートのB列にメールアドレスまたはトークルーム名を指定します。\n＠でカテゴリーを指定することで、カテゴリーごとに設定できます。サブカテゴリーを指定する場合は全ての階層のカテゴリを＠で指定します。\n例） completion_notice@カテゴリー＠サブカテゴリー",
    prompt_expanded_search: "回答が見つからなかったときに、絞り込んだカテゴリー以外の回答候補を表示するか確認するメッセージを指定します。",
    expanded_search: "回答が見つからなかったときに、絞り込んだカテゴリー以外の回答候補を表示する場合は on を指定します。表示しない場合は off を指定します。",
    navigation_expanded_search: "絞り込みの結果が複数あり、かつ not_filtering_num 以下のときに、絞り込んだカテゴリー以外の回答候補を表示するためのボタン名を指定します。",
    sort_by: "カテゴリーと質問文を表示する順序として、score（よく質問される順）もしくは description（FAQデータの記載順）を指定します。指定のない場合は score になります。\nただし、よくある質問の表示順は sort_by によって変更されません。",
    go_back: "ヒアリングや受付のシナリオで、ひとつ前の質問に戻るためのボタン名を指定します。指定のない場合はボタンが非表示になります。",
    auto_link: "回答文やヒアリングシナリオ・受付シナリオのメッセージに記載されたURLとメールアドレスをハイパーリンクにしたい場合に on を指定します。\noff を指定すると、ハイパーリンクを作成しません。",
    search_condition: '複数のキーワードで検索するときの検索方法として、AND検索かOR検索を指定します。指定のない場合は and になります。',
  }
}

export default qa_help;
