import { getSession, saveSession } from 'sites-util/session-storage';

/**
 * Update data in session
 *
 * @export
 * @param {*} data
 */
export function updateSessionFilter(data: any) {
  const oldSession: { [key: string]: any } = getSession("DOMAINS") ? getSession("DOMAINS") : {};
  const newSession = handleSessionFilter(oldSession, data);
  saveSession('DOMAINS', newSession);
}

/**
 * Handle filter in session
 *
 * @param {*} oldSession
 * @param {*} data
 * @returns result to the update
 */
function handleSessionFilter(oldSession: any, data: any) {
  // Get value filterInfo for domainId
  const filterInfo = oldSession[data.domainId] ? oldSession[data.domainId] : '';
  // Check value domainId not exist in session
  if (!filterInfo) {
    oldSession[data.domainId] = {
      startMonth: data.startMonth,
      endMonth: data.endMonth,
      [data.type]: {
        sortColumn: data.sortColumn,
        sortOrder: data.sortOrder
      }
    }
    return oldSession;
  }
  // Update startMonth, endMonth
  if (data.startMonth && data.endMonth) {
    oldSession[data.domainId].startMonth = data.startMonth;
    oldSession[data.domainId].endMonth = data.endMonth;
  }

  oldSession[data.domainId][data.type] = oldSession[data.domainId][data.type] ? oldSession[data.domainId][data.type] : {};
  // Update sortColumn and sortOrder
  if (data.sortColumn !== undefined && data.sortOrder !== undefined) {
    oldSession[data.domainId][data.type].sortColumn = data.sortColumn;
    oldSession[data.domainId][data.type].sortOrder = data.sortOrder;
  }
  if (data.csvCategory !== undefined) {
    oldSession[data.domainId][data.type].csvCategory = data.csvCategory;
  }
  return oldSession;
}


/**
 * Get session filter from session
 *
 * @export
 * @param {*} data
 * @returns results from the session
 */
export function getSessionFilter(data: any) {
  const sessionInfo = getSession("DOMAINS") ? getSession("DOMAINS") : {};
  const filterInfo: any = {};
  if (sessionInfo[data.domainId]) {
    filterInfo.startMonth = sessionInfo[data.domainId].startMonth ? sessionInfo[data.domainId].startMonth : '';
    filterInfo.endMonth = sessionInfo[data.domainId].endMonth ? sessionInfo[data.domainId].endMonth : '';
    if (sessionInfo[data.domainId][data.type]) {
      filterInfo.sortColumn = sessionInfo[data.domainId][data.type].sortColumn;
      filterInfo.sortOrder = sessionInfo[data.domainId][data.type].sortOrder;
      filterInfo.csvCategory = sessionInfo[data.domainId][data.type].csvCategory
    }
  }
  return filterInfo;
}

