
const noDomainPage:string[] = [];

export function currentHash():{ domain:string, page:string } {
  const [domain, page] = location.hash.substr(1).split('/').filter(x => x);
  return { domain, page };
}

export function setHash(domain:string, name:string) {
  location.hash = `#${domain}/${name}`;
}

export function gotoDomain(domain:string) {
  setHash(domain, currentHash().page || 'home');
}

export function gotoPage(name:string) {
  if (noDomainPage.indexOf(name) >= 0) {
    setHash('_', name);
  } else {
    const { domain } = currentHash();
    setHash(domain, name);
  }
}

export function gotoRoot() {
  location.hash = '#';
}

export function gotoLogin() {
  location.hash = '#login';
}

export function gotoLogout() {
  location.hash = '/logout';
}

export function gotoSelectDomain() {
  location.hash = '#undefined/home';
}

// SERVICE UNAVAILABLE
export function gotoServiceUnavailable() {
  location.hash = '#service-unavailable';
}

/**
 * Handle go to screen
 * @param {*} domain
 * @param {*} path
 */
export function gotoScreen(domain:string, path:string = 'home') {
  setHash(domain, path);
}