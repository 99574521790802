class BaseManagement {
  _instance: any;
  _labelObverse: any;

  /**
   * Set instance
   * @param instance
   */
  public setInstance(instance: any) {
    this._instance = instance;
  }

  /**
   * Update
   */
  public update() {
    if (this._instance) {
      this._instance.update();
    }
  }

  /**
   * ReloadData
   * @param param
   */
  public reloadData(params: any = {}) {
    if (this._instance && this._instance.reloadData) {
      return this._instance.reloadData(params);
    }
  }

  public showLoading(show: boolean = true) {
    return show
      ? document.querySelector('loading').setAttribute('class', 'on-loading-content')
      : document.querySelector('loading').removeAttribute('class');
  }

  public showPressedLoading(show: boolean = true) {
    return show
      ? document.getElementById('loading-pressed').style.display = 'block'
      : document.getElementById('loading-pressed').style.display = 'none';
  }
}

export default BaseManagement;
