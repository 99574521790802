import { Autolinker } from 'autolinker';
const escapeHtml = require('escape-html');
const emailPattern = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const isEmail = (email: string) => emailPattern.test(String(email).toLowerCase());

export function mergeNotEmptyAttr(obj1:{ [key:string]:any }, obj2:{ [key:string]:any }) {
  if (!obj1) return obj2;
  for (const key in obj2) {
    if (obj2[key]) {
      if (typeof obj2[key] === 'object') {
        obj1[key] = mergeNotEmptyAttr(obj1[key], obj2[key]);
      } else {
        obj1[key] = obj2[key];
      }
    }
  }
  return obj1;
}

export function subtractObject(obj1:{ [key:string]:any }, obj2:{ [key:string]:any }) {
  if (!obj1) return;
  for (const key in obj2) {
    if (typeof obj1[key] === 'object'){
      if (!subtractObject(obj1[key], obj2[key])) delete obj1[key];
    } else if (obj1[key] == obj2[key]) {
      delete obj1[key];
    }
  }
  return Object.keys(obj1).length > 0 ? obj1 : undefined;
}

// Check link URL and create element html
export function elementGenerator(value: string) {
  if (!value) { return ''};
  let result = escapeHtml(value);
  const matches = Autolinker.parse(result, { phone: false });
  matches.reverse().map((match: any) => {
    const linkText = Autolinker.link(match.getMatchedText());
    const lastIndex = match.getOffset();
    result = result.substr(0, lastIndex) + result.substr(lastIndex).replace(match.getMatchedText(), linkText);
  });
  return result;
}

//format message to hyperlink
export function formatHyperLink(data: any) {
  return data.replace(/\[([^\[\]]*)\]\(([^\(\)]*)\)/gm, (match: any, linkText: any, URL: any) => {
    if (isEmail(URL)) {
      URL = `mailto:${URL}`
    }
    return `<a href="${URL}" target="_blank">${linkText}</a>`;
  });
}

export function sanitizeHTML(value: string) {
  const element = document.createElement('div');
  element.textContent = value;
  return element.innerHTML;
};
