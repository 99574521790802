class PopupUtil {
  private _popupInstance: any;
  
  /**
   * Set PopupInstance
   * @param popupInstance 
   */
  public setPopupInstance(popupInstance: any) {
    this._popupInstance = popupInstance;
  }

  /**
   * Alert function
   * @param content 
   * @param okCallback 
   */
  public alert(content: string, okCallback: Function = null, title: string = null) {
    if (this._popupInstance) {
      this._popupInstance.alert(content, okCallback, title);
    }
  }

  /**
   * Confirm function
   * @param content 
   * @param okCallback 
   * @param cancelCallback 
   */
  public confirm(content: string, okCallback: Function = null, cancelCallback: Function = null, title: string = null) {
    if (this._popupInstance) {
      this._popupInstance.confirm(content, okCallback, cancelCallback, title);
    }
  }

  /**
   * Cancel function
   */
  public cancel() {
    if (this._popupInstance) {
      this._popupInstance.cancel();
    }
  }
}

export default new PopupUtil();
