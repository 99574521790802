
export const widget_base_url = WIDGET_BASE_URL || `${location.protocol}//${location.host}${location.pathname}widget`;
export const getErrorMessageTotalFilesUpload = (maxTotalFilesUpload) => `一度にアップロード出来るファイル数は${maxTotalFilesUpload}までです。`;
export const getErrorMessageFileHasBeenDeleted = (fileName) => `${fileName}は既に削除されています。`;
export const HOME = 'home';
export const FILE_EXPORT_DEFAULT = 'ラベルなし';
export const ALL = 'すべて';
export const FORBIDDEN = 'Forbidden';
export const widget_draft_base_url = `${location.protocol}//${location.host}${location.pathname}`;
export const SUCCESS = 'success';
export const ERROR = 'error';
export const MESSAGE_DELETE_LABEL = 'ラベル指定でデータを削除すると、選択したラベルがついているデータはすべて削除されます。データを削除しますか？';
export const RESET_FAQ_DELETE = 'を削除します。よろしいですか？';
export const RESET_FAQ_REVERT = 'の変更を取り消します。よろしいですか？';
export const RESET_WORDS_DELETE = 'を削除します。よろしいですか？';
export const RESET_WORDS_REVERT = 'の変更を取り消します。よろしいですか？';
export const RESET_APPLICATION_DELETE = 'を削除します。よろしいですか？';
export const RESET_APPLICATION_REVERT = 'の変更を取り消します。よろしいですか？';
export const RESET_HEARING_DELETE = 'を削除します。よろしいですか？';
export const RESET_HEARING_REVERT = 'の変更を取り消します。よろしいですか？';
export const RESET_CONFIG_DELETE = 'の設定を削除します。よろしいですか？';
export const RESET_CONFIG_REVERT = 'の変更を取り消します。よろしいですか？';
export const RESET_LABEL_DELETE = 'を削除します。よろしいですか？';
export const RESET_LABEL_REVERT = 'の変更を取り消します。よろしいですか？';
export const ERROR_LABEL = 'ラベルが不正です。';
export const DISPLAY_ICON_CONFIRM = false;
export const DISPLAY_ICON_ALERT = true;