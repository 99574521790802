const INPUT_REGEX_PATTERN = /(\[\s*input\s*:\s*{.+}\s*\])/;
export const LABEL_INVALID_PATTERN = /[\\/:*<>|"?]|.{33,}/;
export const REGEX_UNICODE = /\ud83c[\udffb-\udfff](?=\ud83c[\udffb-\udfff])|(?:[^\ud800-\udfff][\u0300-\u036f\ufe20-\ufe2f\u20d0-\u20ff]?|[\u0300-\u036f\ufe20-\ufe2f\u20d0-\u20ff]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\ud800-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe2f\u20d0-\u20ff]|\ud83c[\udffb-\udfff])?(?:\u200d(?:[^\ud800-\udfff]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe2f\u20d0-\u20ff]|\ud83c[\udffb-\udfff])?)*/g;
const MessageError = {
  default: (sheetType = DataType.HEARING) => `${sheetType === DataType.HEARING ? 'ヒアリングの' : ''}設定が不正です。`,
  cellError: () => `設定が不正です。`,
  itemNameNotUnique: (itemName: string) => `「${itemName}」は既に登録されています。`,
  identifierNotUnique: (identifier: string) => `イベント「${identifier}」は既に登録されています。`,
  notAcceptAtEndLine: () => `最後の項目名は "_accept" にしてください。`,
  nextItemOptionInitiatedError: () => `設定が不正です。\n有効な遷移先を設定してください。`,
  optionsError: () => `設定が不正です。\n 有効な入力項目（選択肢）を設定してください。`,
  applicationNameInvalid: () => `受付名にはスペースや記号「\\/:*?"<>|[]」を利用できません。`,
  multipleEmptyOption: () => `設定が不正です。\n連続したメッセージは3件以下で設定してください。`,
  itemNameContentReservedWord: () => `設定が不正です。\n項目名に予約語が含まれています。`,
  hearingHasAccept: () => `設定が不正です。\n項目 "_accept" は設定できません。`,
  itemNameHearingNotUnique: (itemName: string) => `設定が不正です。\n「${itemName}」は既に登録されています。`,
  keyOfOptionError: () => `選択肢は64文字以下で入力してください。`,
  itemNameLengthError: () => `設定が不正です。\n項目名は32文字以下で入力してください。`,
  nextItemNameLengthError: (maxLength: number) => `設定が不正です。\n項目名は${maxLength}文字以下で入力してください。`,
  satisfiedEndIsBack: () => `設定が不正です。\n"_satisfied"の最後の「次の項目」は"_top"のみ指定できます。`,
  satisfiedOptionToBackApplication: () => `設定が不正です。\n"_satisfied"の「入力」で遷移先を指定する場合、固定値は"_top" または"_redo"のみ指定できます。`,
  satisfiedOptionToBackHearing: () => `設定が不正です。\n"_satisfied"の「入力」で遷移先を指定する場合、固定値は"_top"のみ指定できます。`,
  itemNameContainForbiddenCharacter: () => `設定が不正です。\n項目名に禁止文字が含まれています。\n空白、改行、タブと一部の記号 #[]>{} は利用できません。`
};

const ScenarioValidation = {
  INPUT_AUTO: '[input:auto]',
  MAX_NUMBER_OPTION: 9,
  MAX_LENGTH_KEY_OPTION: 64,
  MAX_LENGTH_ITEM_NAME: 32,
  /* MAX_LENGTH_ITEM_NAME + 1 + MAX_LENGTH_ITEM_NAME + 1 + MAX_LENGTH_ITEM_NAME */
  MAX_LENGTH_NEXT_ITEM_NAME_TERNARY_OPERATOR: 98,
  FORBIDDEN_CHARACTERS_PATTERN: /[\s>#{}\[\]]/,
  IS_PROPERTY_PATTERN: /^\$\$[\S\s]*\$\$$/,
  PROPERTY_MESSAGE_PATTERN: /^\$\$[a-zA-Z0-9_.]{1,32}\$\$$/,
  OPTION_PATTERN: /^(\[[^\]]+\])(\s*>\s*.+)*$/,
  INPUT_REGEX_PATTERN: /(\[\s*input\s*:\s*{.+}\s*\])/,
  TERNARY_OPERATOR_PATTERN: /^(.+)\?(.+):(.+)$/,
  INPUT_OPTIONS: [
    '[input:text]',
    '[input:num]',
    '[input:full-width]',
    '[input:full-width-hiragana]',
    '[input:full-width-kana]',
    '[input:half-width]',
    '[input:ymd]',
    '[input:md]',
    '[input:hm]',
    '[input:postcode]',
    '[input:tel]',
    '[input:mail]',
    '[input:birthday]',
    '[input:age]',
  ],
  ACCEPT_NUMBER: '{acceptNumber}',
  GET_CONFIRM: '{getConfirm()}',
  ACCEPT: '_accept',
};
const TitleHearing = {
  NOT_FOUND: '_not_found',
  NOT_SATISFIED: '_not_satisfied',
  INITIATED: '_initiated',
  SATISFIED: '_satisfied',
};
// Value finish application
const EndApplicationType = {
  TOP: '_top',
  NEXT: '_next',
  POST: '_post',
  REDO: '_redo',
  FINISH_REDO: '_finish_redo',
};
const EndHearingType = {
  BACK: '_back',
  TOP: '_top',
  NEXT: '_next',
  POST: '_post',
};
const DataType = {
  APPLICATION: 'application',
  HEARING: 'hearing',
};
const CLOSE_SCENARIO = '_close';
const columnNameExcel = { name: 'name', message: 'message', option: 'option', next: 'next' };
const isRegex = (regexStr: any) => {
  try {
    const { pattern, flags } = defineParamRegExp(regexStr);
    new RegExp(pattern, flags);
    return true;
  } catch (error) {
    return false;
  }
}

const defineParamRegExp = (regexStr: any) => {
  let pattern = regexStr;
  let flags = '';
  if (regexStr.startsWith('/')) {
    const lastIndex = regexStr.lastIndexOf('/');
    if (lastIndex !== 0) {
      pattern = regexStr.substring(1, lastIndex);
      flags = regexStr.substring(lastIndex + 1);
    }
  }
  return {
    pattern,
    flags,
  };
};

const escapeRegExp = (string: string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

/**
 * Validate item name of scenario
 * @param lineData
 * @param scenarioInfo
 * @param index
 * @returns error object
 */
function _validateNameItem(item: any, scenarioInfo: any) {
  const allNextItems = scenarioInfo.allNextItems;
  const name = item.name;
  if (name && name.length > ScenarioValidation.MAX_LENGTH_ITEM_NAME) {
    return { index: item.index, item: columnNameExcel.name, errMsg: MessageError.itemNameLengthError() }
  }
  if (!name) {
    return { index: item.index, item: columnNameExcel.name, errMsg: MessageError.cellError() };
  }
  if (name.charAt(0) === '_' && name !== ScenarioValidation.ACCEPT) {
    return { index: item.index, item: columnNameExcel.name, errMsg: MessageError.cellError() };
  }
  if (name.match(ScenarioValidation.FORBIDDEN_CHARACTERS_PATTERN)) {
    return { index: item.index, item: columnNameExcel.name, errMsg: MessageError.itemNameContainForbiddenCharacter() };
  }
  if (allNextItems.indexOf(name) === -1 && item.index > 1) {
    return { index: item.index, item: columnNameExcel.name, errMsg: MessageError.cellError() };
  }
  return undefined;
}

function _validateAcceptLine(lineData: any, endType: any, itemNameActions: any) {
  const columnNameError = _validateMessageItem(lineData, itemNameActions);
  if (columnNameError) {
    return columnNameError;
  }
  if (lineData.options.length) {
    // Throw error if the option contains "input"
    const numberOfInput = lineData.options.filter((option: any) => {
      const optionRemoveSpace = (option || '').replace(/\s/g, '');
      return optionRemoveSpace.startsWith('[input:') && optionRemoveSpace !== '[input:]';
    }).length;
    if (numberOfInput) {
      return columnNameExcel.option;
    }
  }
  if (!lineData.next || !endType.includes(lineData.next)) {
    return columnNameExcel.next;
  }
  return undefined;
}

function _validateMessageItem(lineData: any, itemNameActions: any) {
  if (!lineData.message) {
    return columnNameExcel.message;
  }
  const itemNameMatch = lineData.message.match(/{.*}/);
  if (lineData.message && lineData.message.trim().length > 1024) {
    return columnNameExcel.message;
  }
  if (itemNameMatch) {
    let message = lineData.message;
    for (let action of itemNameActions) {
      // Remove action in message
      message = message.replace(new RegExp(escapeRegExp(action), 'g'), ' ');
    }
    // Remove acceptNumber/getConfirm()
    message = message.replace(new RegExp(escapeRegExp(ScenarioValidation.ACCEPT_NUMBER), 'g'), ' ');
    message = message.replace(new RegExp(escapeRegExp(ScenarioValidation.GET_CONFIRM), 'g'), ' ');
    if (message.match(/{.*}/)) {
      return columnNameExcel.message;
    }
  }
  if (lineData.message.indexOf(ScenarioValidation.ACCEPT_NUMBER) >= 0
    && lineData.name !== ScenarioValidation.ACCEPT) {
    return columnNameExcel.message;
  }
  return undefined;
}

function _validateOptionAndGetNextItem(item: any, scenarioInfo: any) {
  const options = item.options;
  const nextItemOfOption: string[] = [];
  const numberOfInput = options.filter((option: any) => {
    const optionRemoveSpace = option.replace(/\s/g, '');
    return optionRemoveSpace.startsWith('[input:') && optionRemoveSpace !== '[input:]';
  }).length;
  if (numberOfInput > 1 || (options.length - numberOfInput) >= ScenarioValidation.MAX_NUMBER_OPTION) {
    return { index: item.index, item: columnNameExcel.option, errMsg: MessageError.cellError() };
  }
  const optionKeys: any = [];
  for (let optionValue of options) {
    // Check input regex
    if (optionValue.match(ScenarioValidation.INPUT_REGEX_PATTERN)) {
      if (!isRegex(optionValue.replace(/^\[\s*input\s*:\s*{|}\s*\]$/g, ''))) {
        return { index: item.index, item: columnNameExcel.option, errMsg: MessageError.cellError() };
      }
      continue;
    }
    const pattern = optionValue.match(ScenarioValidation.OPTION_PATTERN);
    if (!pattern) {
      return { index: item.index, item: columnNameExcel.option, errMsg: MessageError.cellError() };
    }
    if (pattern[1]) {
      // Validate input
      const inputOption = pattern[1].replace(/\s/g, '');
      if (inputOption.startsWith('[input:') && inputOption !== '[input:]') {
        if (pattern[2] || ScenarioValidation.INPUT_OPTIONS.indexOf(inputOption) === -1) {
          return { index: item.index, item: columnNameExcel.option, errMsg: MessageError.cellError() };
        }
        continue;
      }
      // Validate option select
      const option = pattern[1].replace(/^\[\s*|\s*\]$/g, '');
      if (!option || option.match(/{.*}/) || (option.trim() && option.trim().endsWith(':'))) {
        return { index: item.index, item: columnNameExcel.option, errMsg: MessageError.cellError() };
      }
      const findColon = option.match(/:/g);
      if (findColon && findColon.length > 1) {
        return { index: item.index, item: columnNameExcel.option, errMsg: MessageError.cellError() };
      }
      let optionKey = '';
      if (!findColon || option.match(/^:.*|.*:$/)) {
        optionKey = option;
      } else {
        optionKey = option.split(':')[0].trim();
      }
      // Error when length of key option more than 64 character
      if (optionKey.length > ScenarioValidation.MAX_LENGTH_KEY_OPTION) {
        return { index: item.index, item: columnNameExcel.option, errMsg: MessageError.keyOfOptionError() };
      }
      // Error when duplicate option
      if (optionKeys.includes(optionKey)) {
        return { index: item.index, item: columnNameExcel.option, errMsg: MessageError.cellError() };
      }
      optionKeys.push(optionKey);
    }
    // Validate next item
    const nextItem = pattern[2] ? pattern[2].trim().replace(/\s*>\s*/, '') : '';
    if (nextItem) {
      const validateNextItemResult = validateNextItem(item, scenarioInfo, nextItem);
      if (validateNextItemResult && nextItem !== CLOSE_SCENARIO) {
        return validateNextItemResult;
      }
      nextItemOfOption.push(nextItem);
    }
  }
  return nextItemOfOption;
}

/**
 * Validate next item hearing
 * @param {*} lineData - line data
 * @param {*} scenarioInfo - scenario info
 * @param {*} nextOption - next option
 */
function validateNextItem(item: any, scenarioInfo: any, nextOption: any = undefined) {
  const next = nextOption || item.next;
  const endType = scenarioInfo.endType;
  const sheetType = scenarioInfo.sheetType;
  const identifiers = scenarioInfo.identifiers || [];
  const mm = next.match(ScenarioValidation.TERNARY_OPERATOR_PATTERN);
  const maxLength = mm ? ScenarioValidation.MAX_LENGTH_NEXT_ITEM_NAME_TERNARY_OPERATOR : ScenarioValidation.MAX_LENGTH_ITEM_NAME;
  if (next && next.length > maxLength) {
    return { index: item.index, item: nextOption ? columnNameExcel.option : columnNameExcel.next, errMsg: MessageError.nextItemNameLengthError(maxLength) };
  }
  if (!next) {
    return { index: item.index, item: nextOption ? columnNameExcel.option : columnNameExcel.next, errMsg: MessageError.cellError() };
  }
  if (next.match(ScenarioValidation.FORBIDDEN_CHARACTERS_PATTERN)) {
    return { index: item.index, item: nextOption ? columnNameExcel.option : columnNameExcel.next, errMsg: MessageError.itemNameContainForbiddenCharacter() };
  }
  if (next.charAt(0) === '_') {
    const nextType = sheetType === DataType.APPLICATION ? endType.concat(EndApplicationType.REDO, ScenarioValidation.ACCEPT) : endType;
    if (nextType && nextType.indexOf(next) === -1 ) {
      if (_isSatisfiedEndBack(identifiers, next)) {
        if (sheetType === DataType.APPLICATION) {
          return { index: item.index, item: nextOption ? columnNameExcel.option : columnNameExcel.next, errMsg: MessageError.satisfiedOptionToBackApplication() };
        }
        else {
          return { index: item.index, item: nextOption ? columnNameExcel.option : columnNameExcel.next, errMsg: MessageError.satisfiedOptionToBackHearing() };
        }
      } else {
        return { index: item.index, item: nextOption ? columnNameExcel.option : columnNameExcel.next, errMsg: MessageError.cellError() };
      }
    }
  }
  return undefined;
}

function _isSatisfiedEndBack(identifiers: any, next: any) {
  return identifiers.find((identifier: any) => identifier.startsWith(TitleHearing.SATISFIED)) && next === EndHearingType.BACK ? true : false;
}

function checkValidNextItemAccept(array: any) {
  for (let i = 0; i < array.length - 1; i++) {
    const item = array[i]
    if (item !== CLOSE_SCENARIO && item !== EndHearingType.TOP) {
      return false;
    }
  }
  return true;;
}

function validateLineItem(item: any, scenarioInfo: any) {
  let columnNameError = null;
  let validateResult = {
    errorDetail: {},
    nextItemOfOption: [] as string[],
    nextItemOfAction: [] as string[]
  };
  // Item is not unique
  if (scenarioInfo.items.includes(item.name) && item.name != '_accept') {
    if (scenarioInfo.sheetType === DataType.HEARING) {
      validateResult.errorDetail = { index: item.index, item: columnNameExcel.name, errMsg: MessageError.itemNameHearingNotUnique(item.name) };
      return validateResult;
    }
    validateResult.errorDetail = { index: item.index, item: columnNameExcel.name, errMsg: MessageError.itemNameNotUnique(item.name) };
    return validateResult;
  }
  // Validate name item
  if (item.name.match(/^(getConfirm\(\)|acceptNumber|住所（.*）)(?:$|\})|(?:^|\{)(getConfirm\(\)|acceptNumber|住所（.*）)$/)) {
    validateResult.errorDetail = { index: item.index, item: columnNameExcel.name, errMsg: MessageError.itemNameContentReservedWord() };
    return validateResult;
  }
  if (item.name === ScenarioValidation.ACCEPT && scenarioInfo.sheetType === DataType.HEARING) {
    validateResult.errorDetail = { index: item.index, item: columnNameExcel.name, errMsg: MessageError.hearingHasAccept() };
    return validateResult;
  }
  if (item.index >= 1) {
    const validateNameItemResult = _validateNameItem(item, scenarioInfo);
    if (validateNameItemResult) {
      validateResult.errorDetail = validateNameItemResult;
      return validateResult;
    }
  }
  // Validate _accept line
  if (item.name === ScenarioValidation.ACCEPT && scenarioInfo.sheetType === DataType.APPLICATION) {
    columnNameError = _validateAcceptLine(item, scenarioInfo.endType, scenarioInfo.itemNameActions);
    if (!columnNameError || (columnNameError !== columnNameExcel.option && columnNameError !== columnNameExcel.next)) {
      if (item.options.length) {
        const nextItemResult :any= _validateOptionAndGetNextItem(item, scenarioInfo);
        if (!nextItemResult || !Array.isArray(nextItemResult)) {
          validateResult.errorDetail = nextItemResult
          return validateResult;
        }
        if (Array.isArray(nextItemResult) && !checkValidNextItemAccept(nextItemResult)) {
          validateResult.errorDetail = { index: item.index, item: columnNameExcel.option, errMsg: MessageError.cellError() };
          return validateResult;
        }
      }
    }
    if (columnNameError) {
      if (_isSatisfiedEndBack(scenarioInfo.identifiers || [], item.next)) {
        validateResult.errorDetail = { index: item.index, item: columnNameError, errMsg: MessageError.satisfiedEndIsBack() };
        return validateResult;
      } else {
        validateResult.errorDetail = { index: item.index, item: columnNameError, errMsg: MessageError.cellError() };
        return validateResult;
      }
    }
  }
  let nextItemOfOption;
  // Validate input auto
  if (item.message.match(ScenarioValidation.IS_PROPERTY_PATTERN) && item.message.split('\n').length === 1) {
    if (!item.message.match(ScenarioValidation.PROPERTY_MESSAGE_PATTERN)) {
      validateResult.errorDetail = { index: item.index, item: columnNameExcel.message, errMsg: MessageError.cellError() };
      return validateResult;
    }
    if (item.options.length !== 1) {
      validateResult.errorDetail = { index: item.index, item: columnNameExcel.option, errMsg: MessageError.cellError() };
      return validateResult;
    }
    if (item.options[0].replace(/\s/g, '') !== ScenarioValidation.INPUT_AUTO) {
      validateResult.errorDetail = { index: item.index, item: columnNameExcel.option, errMsg: MessageError.cellError() };
      return validateResult;
    }
  } else {
    // Validate message item
    columnNameError = _validateMessageItem(item, scenarioInfo.itemNameActions);
    if (columnNameError) {
      validateResult.errorDetail = { index: item.index, item: columnNameError, errMsg: MessageError.cellError() };
      return validateResult;
    }
    // Validate option item
    nextItemOfOption = _validateOptionAndGetNextItem(item, scenarioInfo);
    if (item.options.length && nextItemOfOption && !Array.isArray(nextItemOfOption)) {
      validateResult.errorDetail = nextItemOfOption;
      return validateResult;
    }
  }
  // Validate next item
  const validateNextItemResult = validateNextItem(item, scenarioInfo);
  if (validateNextItemResult) {
    validateResult.errorDetail = validateNextItemResult;
    return validateResult;
  }
  const mm = item.next.match(ScenarioValidation.TERNARY_OPERATOR_PATTERN);
  if (mm) {
    validateResult.nextItemOfAction = [mm[2].trim(), mm[3].trim()];
  }
  if (Array.isArray(nextItemOfOption)) {
    validateResult.nextItemOfOption = nextItemOfOption;
  }
  return validateResult;
}

/**
 * Validate identifier
 * @param identifiers
 * @param index
 * @param tpye
 * @returns error object or identifiers array
 */
function _validateGroupIdentifierLine(identifiers: Array<string>, index: number) {
  let result = {
    errDetail: {},
    identifiers: [] as string[]
  }
  for (let i = 0; i < identifiers.length; i++) {
    // Escape identifier name
    identifiers[i] = escapeAtMark(identifiers[i]);
    if (!identifiers[i]) {
      result.errDetail = { index: 0, item: columnNameExcel.name, errMsg: MessageError.cellError() }
      return result;
    }
  }
  if (identifiers.indexOf('_initiated') != -1 && identifiers.length > 1) {
    result.errDetail = { index: 0, item: columnNameExcel.name, errMsg: MessageError.cellError() };
    return result;
  }
  for (let identifier of identifiers) {
    if (!identifier.match(/^_initiated$|^_(satisfied|not_satisfied|not_found)(@.+)*$/)) {
      result.errDetail = { index: 0, item: columnNameExcel.name, errMsg: MessageError.cellError() }
      return result;
    }
  }
  result.identifiers = identifiers;
  return result;
}

function escapeAtMark(path: string) {
  const pathItems = path.split('@');
  let categoryPathItems = [];
  let tempPathItem = '';
  const totalPathItem = pathItems.length;
  for (let index = 0; index < totalPathItem; index++) {
    const pathItem = pathItems[index];
    // Get all "\" characters at the end pathItem string
    const escapeCharacter = pathItem.match(/\\*$/);
    // Case exists "\" characters at the end pathItem string and number of "\" characters  is odd
    if (index < totalPathItem - 1 && escapeCharacter && escapeCharacter.length && (escapeCharacter[0].length % 2) !== 0) {
      tempPathItem += `${pathItem}@`;
      continue;
    }
    tempPathItem = tempPathItem + pathItem;
    // If category is empty
    if (!tempPathItem.length) {
      categoryPathItems = [];
      break;
    }
    categoryPathItems.push(removeEscapeCharacter(tempPathItem));
    tempPathItem = '';
  }
  return categoryPathItems.length ? categoryPathItems.join('@') : undefined;
}

const removeEscapeCharacter = (text: string) => text.replace(/\\@/g, '@').replace(/\\\\/g, '\\').trim();


/**
 * Get end hearing type
 * @param {object} - identifiers
 * @return {Array}
 */
 function getEndHearingType(identifiers:any) {
  if (identifiers.includes(TitleHearing.INITIATED)) {
    return [EndHearingType.NEXT, EndHearingType.POST];
  }
  if (identifiers.find((identifier:any) => { return identifier.startsWith(TitleHearing.SATISFIED) })) {
    return [EndHearingType.TOP];
  }
  return [EndHearingType.TOP, EndHearingType.BACK];
}

function validateScenarioConfig(scenarioConfig:any, sheetType:any) {
  // Validate next item
  const specialNextItem: string[] = [];
  let nextItemsType: string[] = [];
  let endScenarioType = [];
  const numberOfLine = scenarioConfig.data.length;
  let endScenario = scenarioConfig.data[numberOfLine - 1] ? scenarioConfig.data[numberOfLine - 1].lineData.next : '';
  let checkEndTypeAndFaq = false;
  let rowError = scenarioConfig.data[numberOfLine - 1].lineData.index;
  if (sheetType === DataType.APPLICATION) {
    specialNextItem.push(EndApplicationType.REDO, ScenarioValidation.ACCEPT);
    if (scenarioConfig.identifiers.indexOf(TitleHearing.INITIATED) != -1) {
      endScenarioType = [EndApplicationType.NEXT, EndApplicationType.POST];
    } else {
      endScenarioType = [EndApplicationType.TOP];
    }
    nextItemsType = nextItemsType.concat(endScenarioType, specialNextItem);
  } else {
    endScenarioType = getEndHearingType(scenarioConfig.identifiers);
    nextItemsType = nextItemsType.concat(endScenarioType);
  }
  // Number of empty option consecutive
  let numberOfEmptyOption = 0;
  for (let index = 0; index < numberOfLine; index ++) {
    const indexTarget = index + 1;
    const lineData = scenarioConfig.data[index].lineData;
    if (!checkEndTypeAndFaq && lineData.name !== ScenarioValidation.ACCEPT && lineData.options.length && lineData.options[0].replace(/\s/g, '') !== ScenarioValidation.INPUT_AUTO) {
      checkEndTypeAndFaq = true;
    }
    const nextItemsInOptionColumn = lineData.nextItemsInOptionColumn || [];
    const rowIndex = lineData.rowIndex;
    // Remove attribute
    delete scenarioConfig.data[index].nextItemsInOptionColumn;
    delete scenarioConfig.data[index].rowIndex;
    if (!lineData.options.length) {
      numberOfEmptyOption ++;
    } else {
      numberOfEmptyOption = 0;
    }
    if (numberOfEmptyOption > 3) {
      return { index: indexTarget, item: columnNameExcel.message, errMsg: MessageError.multipleEmptyOption() }

    }
    // Validate next item in option column
    for (let nextItem of nextItemsInOptionColumn) {
      // Throw error when setting nextItem is _close at line not at the end
      if (nextItem === CLOSE_SCENARIO) {
        if (index < numberOfLine - 1) {
          return { index: indexTarget, item: columnNameExcel.message, errMsg: MessageError.cellError() }
        }
        continue;
      }
      // Next item in option is not end scenario or next item is not exists in lines after
      if ((nextItem === EndApplicationType.FINISH_REDO || nextItemsType.indexOf(nextItem) === -1)
        && scenarioConfig.items.findIndex((itemName: any) => itemName === nextItem) < index) {
        if (_isSatisfiedEndBack(scenarioConfig.identifiers, nextItem)) {
          if(sheetType === DataType.APPLICATION) {
            return { index: indexTarget, item: columnNameExcel.option, errMsg: MessageError.satisfiedOptionToBackApplication() };
          }
          else {
            return { index: indexTarget, item: columnNameExcel.option, errMsg: MessageError.satisfiedOptionToBackHearing() }
          }
        } else {
          return { index: indexTarget, item: columnNameExcel.option, errMsg: MessageError.cellError() }
        }
      }
      if (endScenarioType.indexOf(nextItem) !== -1 && endScenario !== nextItem && scenarioConfig.identifiers.includes(TitleHearing.INITIATED)) {
        return { index: indexTarget, item: columnNameExcel.option, errMsg: MessageError.nextItemOptionInitiatedError() }
      }
    }
    if (index === numberOfLine - 1) {
      // Not setting _accept at last line (Application)
      if (sheetType === DataType.APPLICATION && lineData.name !== ScenarioValidation.ACCEPT) {
        return { index: indexTarget, item: columnNameExcel.name, errMsg: MessageError.notAcceptAtEndLine() };
      }
      if (sheetType === DataType.HEARING && endScenarioType.indexOf(lineData.next) === -1) {
        if (_isSatisfiedEndBack(scenarioConfig.identifiers, lineData.next)) {
          return { index: indexTarget, item: columnNameExcel.next, errMsg: MessageError.satisfiedEndIsBack() };
        } else {
          return { index: indexTarget, item: columnNameExcel.next, errMsg: MessageError.cellError() };
        }
      }
      break;
    }
    // Validate next item in next item column
    const notExistItem = (next: string) => specialNextItem.indexOf(next) === -1 && scenarioConfig.items.findIndex((item: any) => item === next) < index;
    const mm = lineData.next.match(ScenarioValidation.TERNARY_OPERATOR_PATTERN);
    if (mm) {
      if (notExistItem(mm[1].trim()) || notExistItem(mm[2].trim()) || notExistItem(mm[3].trim())) {
        return { index: indexTarget, item: columnNameExcel.next, errMsg: MessageError.cellError()};
      }
    } else if (notExistItem(lineData.next)) {
      return { index: indexTarget, item: columnNameExcel.next, errMsg: MessageError.cellError()};
    }
  }
  if (!checkEndTypeAndFaq && rowError && endScenario === EndApplicationType.FINISH_REDO) {
    return { index: rowError, item: columnNameExcel.next, errMsg: MessageError.optionsError() };
  }
  return true;
}

/**
 * Get unique value
 * @param value
 * @param index
 * @param self
 * @returns true or false
 */
function getUnique(value: any, index: any, self: any) {
  return self.indexOf(value) === index;
}

export function validateApplication(scenarioConfig: any) {
  const indentifierItem = scenarioConfig[0].name.trim();
  const resultIdent = _validateGroupIdentifierLine(indentifierItem.split(/[\r\n]+|\n+/), 1);
  if (Object.keys(resultIdent.errDetail).length ) {
    return resultIdent.errDetail;
  }
  const identifiers = resultIdent.identifiers;
  if (identifiers.length !== identifiers.filter(getUnique).length) {
    return { index: 0, item: columnNameExcel.name, errMsg: MessageError.cellError() };
  }
  const endOfInitiated = [];
  const endOfNormal = [];
  let endType = [];
  endOfInitiated.push(EndApplicationType.POST, EndApplicationType.NEXT, EndApplicationType.FINISH_REDO);
  endOfNormal.push(EndApplicationType.TOP);
  endType = identifiers.indexOf(TitleHearing.INITIATED) != -1 ? endOfInitiated : endOfNormal;
  let senario: any = {
    items: [],
    identifiers: identifiers,
    itemNameActions: [],
    data: [],
    allNextItems:[],
    endType: endType,
    sheetType: DataType.APPLICATION,
  };
  for (let index = 1; index < scenarioConfig.length; index++) {
    const item = scenarioConfig[index];
    const name = item.name || '';
    const message = item.message || '';
    const options = item.options ? item.options : [];
    const next = item.next || '';
    const nextItemsInOptionColumn :string[] = [];
    const lineData = { name, message, options, next, index, nextItemsInOptionColumn };
    const validateResult = validateLineItem(lineData, senario);
    if (Object.keys(validateResult.errorDetail).length) {
      return validateResult.errorDetail;
    }
    senario.items.push(name);
    if (validateResult.nextItemOfOption && validateResult.nextItemOfOption.length) {
      senario.allNextItems.push(...validateResult.nextItemOfOption);
    }
    if (validateResult.nextItemOfAction && validateResult.nextItemOfAction.length) {
      senario.allNextItems.push(...validateResult.nextItemOfAction);
    }
    senario.allNextItems.push(lineData.next);
    if (item.options.find((option: any) => option.replace(/\s/g, '') === '[input:postcode]')) {
      senario.itemNameActions.push(`{住所（${lineData.name}）}`);
    }
    senario.itemNameActions.push(`{${lineData.name}}`);
    lineData.nextItemsInOptionColumn = validateResult.nextItemOfOption || [];
    senario.data.push({ lineData });
    senario.items.push(lineData.name);
  }
  return validateScenarioConfig(senario, DataType.APPLICATION);
}

export function validateHearingFrom(scenarioConfig: any) {
  const indentifierItem = scenarioConfig[0].name.trim();
  const resultIdent = _validateGroupIdentifierLine(indentifierItem.split(/[\r\n]+|\n+/), 1);
  if (Object.keys(resultIdent.errDetail).length ) {
    return  resultIdent.errDetail;
  }
  const identifiers = resultIdent.identifiers;
  if (identifiers.length !== identifiers.filter(getUnique).length) {
    return { index: 0, item: columnNameExcel.name, errMsg: MessageError.cellError() };
  }
  const endOfInitiated = [];
  const endOfNormal = [];
  let endType = [];
  endOfInitiated.push(EndHearingType.POST, EndHearingType.NEXT);
  endOfNormal.push(EndHearingType.TOP, EndHearingType.BACK);
  endType = identifiers.indexOf(TitleHearing.INITIATED) != -1 ? endOfInitiated : endOfNormal;
  let senario: any = {
    items: [],
    identifiers: identifiers,
    itemNameActions: [],
    data: [],
    allNextItems:[],
    sheetType: DataType.HEARING,
    endType: endType,
  };
  for (let index = 1; index < scenarioConfig.length; index++) {
    const item = scenarioConfig[index];
    const name = item.name || '';
    const message = item.message || '';
    const options = item.options ? item.options : [];
    const next = item.next || '';
    const nextItemsInOptionColumn :string[] = [];
    const lineData = { name, message, options, next, index, nextItemsInOptionColumn };
    const validateResult = validateLineItem(lineData, senario);
    if (Object.keys(validateResult.errorDetail).length) {
      return validateResult.errorDetail;
    }
    senario.items.push(name);
    if (validateResult.nextItemOfOption && validateResult.nextItemOfOption.length) {
      senario.allNextItems.push(...validateResult.nextItemOfOption);
    }
    if (validateResult.nextItemOfAction && validateResult.nextItemOfAction.length) {
      senario.allNextItems.push(...validateResult.nextItemOfAction);
    }
    senario.allNextItems.push(lineData.next);
    if (item.options.find((option: any) => option.replace(/\s/g, '') === '[input:postcode]')) {
      senario.itemNameActions.push(`{住所（${lineData.name}）}`);
    }
    senario.itemNameActions.push(`{${lineData.name}}`);
    lineData.nextItemsInOptionColumn = validateResult.nextItemOfOption || [];
    senario.data.push({ lineData });
    senario.items.push(lineData.name);
  }
  return validateScenarioConfig(senario, DataType.HEARING);
}
