// IE11 で Symbol is undefined が表示されることへの対応
// https://babeljs.io/docs/usage/polyfill/
import "babel-polyfill";

// IE11 で fetch を使用すると = のエラーが出るため Polyfill で対応
import 'whatwg-fetch';

// IE/Safari で input type='color' が未対応
import 'spectrum-colorpicker';
const $ = require('jquery');
if (! $.fn.spectrum.inputTypeColorSupport()) {
  require('spectrum-colorpicker/spectrum.css');
  $.fn.spectrum.load = false;
}
