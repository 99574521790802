import BaseManagement from './base/base-management';

class LabelManagement extends BaseManagement {
  _tabActive: string = 'faq';
  _reloadData: boolean = true;

  public getListLabels() {
    if (!this._instance) {
      return [];
    }
    return this._instance.listLabels;
  }

  public async getSelectedLabels() {
    if (!this._instance) {
      return undefined;
    }
    if (this._reloadData) {
      await this._instance.reloadData();
    }
    this.setReloadData(true);
    return this._instance.selectedLabels;
  }

  public setReloadData(reloadData: boolean) {
    this._reloadData = reloadData;
  }

  public setTabActive(tab: string) {
    this._tabActive = tab;
  }

  public getTabActive(tab: string) {
    return this._tabActive;
  }
}

export default new LabelManagement();
