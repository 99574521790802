import { urlWithQuery } from './fetch';

/**
 * Get filename from content disposition header
 * @param res 
 */
export function getFilenameFromDisposition(res: Response): string {
  // Get content disposition header
  const disposition = decodeURIComponent(res.headers.get("content-disposition"));
  // Get filename
  let filename = '';
  if (disposition && disposition.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) { 
      filename = matches[1].replace(/['"]/g, '');
    }
  }
  return filename;
}


// Check string is json format
export function isJsonString(str: string): boolean {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

/**
 * Sent request download csv
 * @param url 
 * @param system 
 * @param obj 
 */
export function downloadCSV(url: string, system?: string, obj?: { [key: string]: any }) {
  const body = obj && Object.keys(obj).reduce((o,key) => (o.append(key, obj[key]), o), new FormData());
  return fetch(urlWithQuery(url, system), { method:'POST', body, credentials: 'same-origin' });
};